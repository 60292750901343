import Companies from '../Component/features/TT_ELD_Admin/User/Companies/Companies';
import Users from '../Component/features/TT_ELD_Admin/User/Users/Users';
import UserCompanies from '../Component/features/TT_ELD_Admin/UserCompanies/UserCompanies';
import VehiclesCompany from '../Component/features/TT_ELD_Admin/User/Vehicles/VehiclesCompany';
import Supports from '../Component/features/TT_ELD_Admin/User/Support/Supports';
import LoadController from '../Component/features/All_TMS_Admin/LoadController/LoadController';
import BrokerController from '../Component/features/All_TMS_Admin/BrokerController/BrokerController';
import CityController from '../Component/features/All_TMS_Admin/CityController/CityController';
import CustomerController from '../Component/features/All_TMS_Admin/CustomerController/CustomerController';
import Tag from '../Component/features/All_TMS_Admin/TagController/Tag';
import ZipCode from '../Component/features/All_TMS_Admin/ZipCode/ZipCode';
import Category from '../Component/features/All_TMS_Admin/Category/Category';
import Contact from '../Component/features/All_TMS_Admin/Contact/Contact';
import Device from '../Component/features/All_TMS_Admin/Device/Device';
import Company from '../Component/features/TT_ELD_Admin/User/Companies/Company/Company';
import User from '../Component/features/TT_ELD_Admin/User/Users/User/User';
import LogEdit from '../Component/features/TT_ELD_Admin/User/Users/Logs/Log/LogEdit';
import UserCompaniesEdit from '../Component/features/TT_ELD_Admin/UserCompanies/UserCompaniesEdit/UserCompaniesEdit';
import VehicleEdit from '../Component/features/TT_ELD_Admin/User/Vehicles/VehicleData/VehicleEdit/VehicleEdit';
import SupportMain from '../Component/features/TT_ELD_Admin/CompanyErrors/CompanyErrors/SupportHistory/SupportMain';
import LoadControllerEdit from '../Component/features/All_TMS_Admin/LoadController/LoadControllerTable/LoadControllerEdit';
import LoadControllerAdd from '../Component/features/All_TMS_Admin/LoadController/LoadControllerTable/LoadControllerAdd';
import BrokerControllerEdit from '../Component/features/All_TMS_Admin/BrokerController/BrokerControllerTable/BrokerControllerEdit';
import AddBrokerController from '../Component/features/All_TMS_Admin/BrokerController/BrokerControllerTable/AddBrokerController';
import CityControllerEdit from '../Component/features/All_TMS_Admin/CityController/CityControllerTable/CityControllerEdit';
import AddCityController from '../Component/features/All_TMS_Admin/CityController/CityControllerTable/AddCityController';
import CustomerControllerEdit from '../Component/features/All_TMS_Admin/CustomerController/CustomerControllerTable/CustomerControllerEdit';
import TagControllerEdit from '../Component/features/All_TMS_Admin/TagController/TagControllerTable/TagControllerEdit';
import AddTagController from '../Component/features/All_TMS_Admin/TagController/TagControllerTable/AddTagController';
import ZipCodeEdit from '../Component/features/All_TMS_Admin/ZipCode/ZipCodeTable/ZipCodeEdit';
import AddZipCode from '../Component/features/All_TMS_Admin/ZipCode/ZipCodeTable/AddZipCode';
import CategoryEdit from '../Component/features/All_TMS_Admin/Category/CategoryTable/CategoryEdit';
import AddContact from '../Component/features/All_TMS_Admin/Contact/ContactTable/AddContact';
import DeviceEdit from '../Component/features/All_TMS_Admin/Device/DeviceTable/DeviceEdit';
import AddDevice from '../Component/features/All_TMS_Admin/Device/DeviceTable/AddDevice';
import ContactEdit from '../Component/features/All_TMS_Admin/Contact/ContactTable/ContactEdit';
import TopErrors from '../Component/features/TT_ELD_Admin/CompanyErrors/TopErrorsCompany/TopErrors';
import Logs from '../Component/features/TT_ELD_Admin/Logs/Logs';
import DevicesEld from '../Component/features/TT_ELD_Admin/Team/DevicesEld/DevicesEld';
import ServerConfiguration from '../Component/features/TT_ELD_Admin/Team/ServerConfiguration/ServerConfiguration';
import Approval from '../Component/features/TT_ELD_Admin/Approval/Approval';
import AddCustomerController from '../Component/features/All_TMS_Admin/CustomerController/CustomerControllerTable/AddCustomerController';
import { MenuProps } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ApprovalCount from '../Component/features/TT_ELD_Admin/Approval/ApprovalCount';
import FMCSA from '../Component/features/TT_ELD_Admin/FMCSA/FMCSA';
import FmcsaCount from '../Component/features/TT_ELD_Admin/FMCSA/FMCSA_Count';
import Report from '../Component/features/All_TMS_Admin/Report/Report';
import Model from '../Component/features/TT_ELD_Admin/User/Make - Model/Model/Model';
import Make from '../Component/features/TT_ELD_Admin/User/Make - Model/Make/Make';
import ModelEdit from '../Component/features/TT_ELD_Admin/User/Make - Model/Model/ModelEdit';
import MakeEdit from '../Component/features/TT_ELD_Admin/User/Make - Model/Make/MakeEdit';
import ActiveLoads from '../Component/features/All_TMS_Admin/AdminApp/ActiveLoad/ActiveLoads';
import LoadHistories from '../Component/features/All_TMS_Admin/AdminApp/LoadHistories/LoadHistories';
import LoadFiles from '../Component/features/All_TMS_Admin/AdminApp/LoadFiles/LoadFiles';
import Note from '../Component/features/All_TMS_Admin/AdminApp/Note/Note';
import SalaryList from '../Component/features/All_TMS_Admin/AdminApp/SalaryList/SalaryList';
import LoadHistoryEdit from '../Component/features/All_TMS_Admin/AdminApp/LoadHistories/LoadHistoryEdit';
import System from '../Component/features/TT_ELD_Admin/Team/System/System';
import {
  DesktopOutlined,
  UserOutlined,
  WarningOutlined,
  QrcodeOutlined,
  FolderOpenOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
import { UserLocalStorage } from '../Component/interface/user/user';
type MenuItem = Required<MenuProps>['items'][number];

const getUserFromLocalStorage = () => {
  const userString = localStorage.getItem('user');
  try {
    const parsedUser = JSON.parse(userString || '{}');

    if (typeof parsedUser === 'object' && parsedUser !== null) {
      return parsedUser as UserLocalStorage;
    } else {
      return {} as UserLocalStorage;
    }
  } catch (e) {
    console.error('Error parsing user from localStorage', e);
    return {} as UserLocalStorage;
  }
};

const user = getUserFromLocalStorage();
const proFeatures =
  user?.config?.pro_features !== null && user?.config?.pro_features !== undefined
    ? user?.config?.pro_features
    : false;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export const allMenu: MenuItem[] = [
  getItem('Account', 'sub1', <ApprovalCount />, [
    getItem(<Link to="/">Approval</Link>, '/'),
    getItem(<Link to="/top-errors">TopErrors</Link>, '/top-errors'),
  ]),
  getItem(<Link to="/fmcsa">FMCSA</Link>, '/fmcsa', <FmcsaCount />),
  getItem(<Link to="/logs">Logs</Link>, '/logs', <DesktopOutlined rev={undefined} />),
  getItem(
    <Link to="/user-companies">UserCompanies</Link>,
    '/user-companies',
    <ReconciliationOutlined rev={undefined} />,
  ),

  getItem('User', 'sub2', <UserOutlined rev={undefined} />, [
    getItem(<Link to="user/supports">Supports</Link>, '/supports'),
    getItem(<Link to="/users">User</Link>, '/users'),
    getItem(<Link to="/companies">Companies</Link>, '/companies'),
    getItem(<Link to="/vehicles">Vehicles</Link>, '/vehicles'),
    getItem(<Link to="/makes">Makes</Link>, '/makes'),
    getItem(<Link to="/models">Models</Link>, '/models'),
  ]),
  getItem('Errors', 'sub3', <WarningOutlined rev={undefined} />, [
    getItem(<Link to="/support-errors">CompanyErrors</Link>, '/support-errors'),
    getItem(<Link to="/top-errors">TopErrors</Link>, '/top-errors'),
  ]),
  getItem('Team', 'sub4', <FolderOpenOutlined rev={undefined} />, [
    getItem(<Link to="/server-configuration">ServerConfiguration</Link>, '/server-configuration'),
    getItem(<Link to="/devices-eld">DevicesEld</Link>, '/devices-eld'),
    getItem(<Link to="/system">System</Link>, '/system'),
  ]),
  getItem('TMS', 'sub5', <QrcodeOutlined rev={undefined} />, [
    getItem('Submenu', 'sub6', null, [
      getItem(<Link to="/active-loads">ActiveLoads</Link>, '/active-loads'),
      getItem(<Link to="/load-histories">LoadHistories</Link>, '/load-histories'),
      getItem(<Link to="/load-files">LoadFiles</Link>, '/load-files'),
      getItem(<Link to="/note">Note</Link>, '/note'),
      getItem(<Link to="/salary-list">SalaryList</Link>, '/salary-list'),
    ]),
    getItem(<Link to="/load">Load</Link>, '/load'),
    getItem(<Link to="/broker-controller">Broker</Link>, '/broker-controller'),
    getItem(<Link to="/city-controller">City</Link>, '/city-controller'),
    getItem(<Link to="/customer-controller">Location</Link>, '/customer-controller'),
    getItem(<Link to="/tag-controller">Tag</Link>, '/tag-controller'),
    getItem(<Link to="/zipCode-controller">ZipCode</Link>, '/zipCode-controller'),
    getItem(<Link to="/category">Category</Link>, '/category'),
    getItem(<Link to="/contact">Contact</Link>, '/contact'),
    getItem(<Link to="/device">Device</Link>, '/device'),
    getItem(<Link to="/report">Reports</Link>, '/report'),
  ]),
  // getItem('Drivers', 'sub7', <ShopOutlined rev={undefined}/>, [
  //         getItem(<Link to="/active-loads">ActiveLoads</Link>, '/active-loads'),
  //         getItem(<Link to="/load-histories">LoadHistories</Link>, '/load-histories'),
  //         getItem(<Link to="/load-files">LoadFiles</Link>, '/load-files'),
  //         getItem(<Link to="/note">Note</Link>, '/note'),
  //         getItem(<Link to="/salary-list">SalaryList</Link>, '/salary-list'),
  //     ]
  // ),
];

export const items: Array<any> = [
  {
    path: '/',
    component: <Approval />,
    key: '/',
  },
  {
    path: '/fmcsa',
    component: <FMCSA />,
    key: '/fmcsa',
  },
  {
    path: '/logs',
    component: <Logs />,
    key: '/logs',
  },

  {
    path: 'logs/log/:logId/:id',
    component: <LogEdit />,
    key: '/logs/logs',
  },
  {
    path: '/logs/logzeros/:logId/:id',
    component: <LogEdit />,
    key: '/logs/logzeros/:logId',
  },
  {
    path: '/system',
    component: <System />,
    key: '/system',
  },
  {
    path: '/companies',
    component: <Companies />,
    key: '/companies',
  },
  {
    path: '/companies/:id',
    component: <Company />,
    key: '/companies/:id',
  },

  {
    path: '/users',
    component: <Users />,
    key: '/users',
  },
  {
    path: '/users/:id',
    component: <User />,
    key: '/users/:id',
  },
  {
    path: '/users/:id/log/:logId/:id',
    component: <LogEdit />,
    key: '/users/:id/log/:logId',
  },
  {
    path: '/users/:id/logzeros/:logId/:id',
    component: <LogEdit />,
    key: '/users/:id/logzeros/:logId',
  },
  {
    path: '/user-companies',
    component: <UserCompanies uid={''} />,
    key: '/user-companies',
  },
  {
    path: '/user-companies/:id',
    component: <UserCompaniesEdit />,
    key: '/user-companies/:id',
  },
  {
    path: '/vehicles',
    component: <VehiclesCompany />,
    key: '/vehicles',
  },

  {
    path: '/makes',
    component: <Make />,
    key: '/makes',
  },
  {
    path: '/makes/:id',
    component: <MakeEdit />,
    key: '/makes/:id',
  },
  {
    path: '/models',
    component: <Model />,
    key: '/models',
  },
  {
    path: '/models/:id',
    component: <ModelEdit />,
    key: '/models/:id',
  },
  {
    path: '/supports',
    component: <Supports />,
    key: '/supports',
  },
  {
    path: '/support-errors/:id',
    component: <SupportMain />,
    key: '/support-errors/:id',
  },
  {
    path: '/support-errors/:id/:id',
    component: <UserCompanies uid={''} />,
    key: '/support-errors/:id/:id',
  },
  {
    path: '/top-errors',
    component: <TopErrors />,
    key: '/top-errors',
  },
  {
    path: '/top-errors/:id/:id',
    component: <UserCompanies uid={''} />,
    key: '/top-errors/:id/:id',
  },
  {
    path: '/devices-eld',
    component: <DevicesEld />,
    key: '/devices-eld',
  },
  {
    path: '/server-configuration',
    component: <ServerConfiguration />,
    key: '/server-configuration',
  },
  {
    path: '/load-controller',
    component: <LoadController />,
    key: '/load-controller',
  },
  {
    path: '/load-controller/:id',
    component: <LoadControllerEdit />,
    key: '/load-controller/:id',
  },
  {
    path: '/load-controllerAdd',
    component: <LoadControllerAdd />,
    key: '/load-controllerAdd',
  },
  {
    path: '/broker-controller',
    component: <BrokerController />,
    key: '/broker-controller',
  },
  {
    path: '/broker-controller/:id',
    component: <BrokerControllerEdit />,
    key: '/broker-controller/:id',
  },
  {
    path: '/broker-controllerAdd',
    component: <AddBrokerController />,
    key: '/broker-controllerAdd',
  },
  {
    path: '/city-controller',
    component: <CityController />,
    key: '/city-controller',
  },
  {
    path: '/city-controller/:id',
    component: <CityControllerEdit />,
    key: '/city-controller/:id',
  },
  {
    path: '/city-controllerAdd',
    component: <AddCityController />,
    key: '/city-controllerAdd',
  },
  {
    path: '/customer-controller',
    component: <CustomerController />,
    key: '/customer-controller',
  },
  {
    path: '/customer-controller/:id',
    component: <CustomerControllerEdit />,
    key: '/customer-controller/:id',
  },
  {
    path: '/customer-controllerAdd',
    component: <AddCustomerController />,
    key: '/customer-controllerAdd',
  },
  {
    path: '/tag-controller',
    component: <Tag />,
    key: '/tag-controller',
  },
  {
    path: '/tag-controller/:id',
    component: <TagControllerEdit />,
    key: '/tag-controller/:id',
  },
  {
    path: '/tag-controllerAdd',
    component: <AddTagController />,
    key: '/tag-controllerAdd',
  },
  {
    path: '/zipCode-controller',
    component: <ZipCode />,
    key: '/zipCode-controller',
  },
  {
    path: '/zipCode-controller/:id',
    component: <ZipCodeEdit />,
    key: '/zipCode-controller/:id',
  },
  {
    path: '/zipCode-controllerAdd',
    component: <AddZipCode />,
    key: '/zipCode-controllerAdd',
  },
  {
    path: '/category',
    component: <Category />,
    key: '/category',
  },
  {
    path: '/category/:id',
    component: <CategoryEdit />,
    key: '/category/:id',
  },
  {
    path: '/contact',
    component: <Contact />,
    key: '/contact',
  },
  {
    path: '/contact/:id',
    component: <ContactEdit />,
    key: '/contact/:id',
  },
  {
    path: '/contactAdd',
    component: <AddContact />,
    key: '/contactAdd',
  },
  {
    path: '/device',
    component: <Device />,
    key: '/device',
  },
  {
    path: '/device/:id',
    component: <DeviceEdit />,
    key: '/device/:id',
  },
  {
    path: '/deviceAdd',
    component: <AddDevice />,
    key: '/deviceAdd',
  },
  {
    path: '/report',
    component: <Report />,
    key: '/report',
  },
  {
    path: '/active-loads',
    component: <ActiveLoads />,
    key: '/active-loads',
  },
  {
    path: '/load-histories',
    component: <LoadHistories />,
    key: '/load-histories',
  },
  {
    path: '/load-histories/:id',
    component: <LoadHistoryEdit />,
    key: '/load-histories/:id',
  },
  {
    path: '/load-files',
    component: <LoadFiles />,
    key: '/load-files',
  },
  {
    path: '/note',
    component: <Note />,
    key: '/note',
  },
  {
    path: '/salary-list',
    component: <SalaryList />,
    key: '/salary-list',
  },
];
// const userMe: MyObjectType = useUsersMe('222');
export const result = [
  // {
  //     code: "dashboard",
  //     icon: "dashboard",
  //     label: {zh_CN: '首页', en_US: 'Dashboard'},
  //     path: "/dashboard"
  // },

  {
    code: 'account',
    icon: 'account',
    label: { zh_CN: '帐户', en_US: 'New Accounts' },
    path: '/account',
    children: [
      {
        code: 'approval',
        label: { zh_CN: '赞同', en_US: 'Account approval' },
        path: '/account/approval',
      },
      {
        code: 'rejected-users',
        label: { zh_CN: '拒绝用户', en_US: 'Rejected accounts' },
        path: '/account/rejected-users',
      },
    ],
  },
  {
    code: 'fmcsa',
    icon: 'fmcsa',
    label: { zh_CN: '首页', en_US: 'FMCSA' },
    path: '/fmcsa',
  },
  {
    code: 'contact',
    icon: 'contact',
    label: { zh_CN: '接触', en_US: 'Contact' },
    path: '/contact',
  },
  {
    code: 'unpaid-companies',
    icon: 'unpaid-companies',
    label: { zh_CN: '未付薪水的公司', en_US: 'Unpaid Companies' },
    path: '/disabled-companies',
  },
  {
    code: 'disputed-companies',
    icon: 'disputed-companies',
    label: { zh_CN: '未付薪水的公司', en_US: 'Disputed Companies' },
    path: '/disputed-companies',
    config: proFeatures,
  },
  {
    code: 'user-companies',
    icon: 'user-companies',
    label: { zh_CN: '用户公司', en_US: 'User Companies' },
    path: '/user-companies',
  },
  // {
  //   code: 'camera',
  //   icon: 'camera',
  //   label: { zh_CN: '接触', en_US: 'Cameras' },
  //   path: '/camera',
  //   children: [
  //     {
  //       code: 'cameras',
  //       label: { zh_CN: '拒绝用户', en_US: 'Cameras' },
  //       path: '/camera/cameras',
  //     },
  //     {
  //       code: 'assigned-cameras',
  //       label: { zh_CN: '接触', en_US: 'Assigned Cameras' },
  //       path: '/camera/assigned-cameras',
  //     },
  //     {
  //       code: 'push-events',
  //       label: { zh_CN: '拒绝用户', en_US: 'Push Events' },
  //       path: '/camera/push-events',
  //     },
  //     {
  //       code: 'push-alerts',
  //       label: { zh_CN: '拒绝用户', en_US: 'Push Alerts' },
  //       path: '/camera/push-alerts',
  //     },
  //   ],
  // },
  {
    code: 'user',
    icon: 'user',
    label: { zh_CN: '用户', en_US: 'Users' },
    path: '/user',
    children: [
      {
        code: 'admins',
        label: { zh_CN: '調度員', en_US: 'Admins' },
        path: '/user/admins',
      },
      {
        code: 'dispatcher',
        label: { zh_CN: '調度員', en_US: 'Dispatchers' },
        path: '/user/dispatcher',
      },
      {
        code: 'users',
        label: { zh_CN: '用户', en_US: 'Drivers' },
        path: '/user/users',
      },
      {
        code: 'companies',
        label: { zh_CN: '公司', en_US: 'Companies' },
        path: '/user/companies',
      },
      {
        code: 'owners',
        label: { zh_CN: '支持', en_US: 'Owners' },
        path: '/user/owners',
      },
      {
        code: 'supports',
        label: { zh_CN: '支持', en_US: 'Supports' },
        path: '/user/supports',
      },
      {
        code: 'vehicles',
        label: { zh_CN: '汽车', en_US: 'Vehicles' },
        path: '/user/vehicles',
      },
      {
        code: 'makes',
        label: { zh_CN: '制作', en_US: 'Makes' },
        path: '/user/makes',
      },
      {
        code: 'models',
        label: { zh_CN: '模型', en_US: 'Models' },
        path: '/user/models',
      },
      {
        code: 'documents',
        label: { zh_CN: '模型', en_US: 'Documents' },
        path: '/user/documents',
      },
      {
        code: 'logbook',
        label: { zh_CN: '模型', en_US: 'LogBook' },
        path: '/user/logbook',
      },
    ],
  },
  {
    code: 'errors',
    icon: 'errors',
    label: { zh_CN: '错误', en_US: 'Errors' },
    path: '/errors',
    children: [
      {
        code: 'company-errors',
        label: { zh_CN: '支持', en_US: 'Company Errors' },
        path: '/errors/company-errors',
      },
      {
        code: 'top-errors',
        label: { zh_CN: '用户', en_US: 'Top Errors' },
        path: '/errors/top-errors',
      },
    ],
  },
  {
    code: 'team',
    icon: 'team',
    label: { zh_CN: '错误', en_US: 'Team' },
    path: '/team',
    children: [
      {
        code: '/team/histories',
        label: { zh_CN: 'Истории', en_US: 'Histories' },
        path: '/team/histories',
      },
      {
        code: '/team/server-configuration',
        label: { zh_CN: '服务器配置', en_US: 'Server Configs' },
        path: '/team/server-configuration',
      },
      {
        code: 'team/devices-eld',
        label: { zh_CN: '设备领域', en_US: 'Devices ELD' },
        path: '/team/devices-eld',
      },
      // {
      //   code: 'team/builds',
      //   label: { zh_CN: 'Строит', en_US: 'Builds' },
      //   path: '/team/builds',
      // },
      {
        code: 'team/system',
        label: { zh_CN: '系統', en_US: 'System' },
        path: '/team/system',
      },
      {
        code: 'team/subscription',
        label: { zh_CN: '系統', en_US: 'Subscriptions' },
        path: '/team/subscription',
      },
      {
        code: 'team/check-stripe',
        label: { zh_CN: '格紋條紋', en_US: 'Check Stripe ' },
        path: '/team/check-stripe',
      },
      {
        code: 'team/device-version',
        label: { zh_CN: '系統', en_US: 'Device Version' },
        path: '/team/device-version',
      },
      {
        code: 'team/provider',
        label: { zh_CN: 'Поставщик', en_US: 'Providers' },
        path: '/team/provider',
      },
      {
        code: 'team/provider-activity',
        label: { zh_CN: 'Активность провайдера', en_US: 'Provider Activities' },
        path: '/team/provider-activity',
      },
      {
        code: 'team/provider-request',
        label: { zh_CN: 'Запрос поставщика', en_US: 'Provider Requests' },
        path: '/team/provider-request',
      },
    ],
  },
  {
    code: 'tms',
    icon: 'tms',
    label: { zh_CN: '时间管理系统', en_US: 'TMS' },
    path: '/tms',
    children: [
      {
        code: 'active-loads',
        label: { zh_CN: '有源负载', en_US: 'Active Loads' },
        path: '/tms/active-loads',
      },
      {
        code: 'load-histories',
        label: { zh_CN: '加载历史记录', en_US: 'Load Histories' },
        path: '/tms/load-histories',
      },
      {
        code: 'load-files',
        label: { zh_CN: '加载文件', en_US: 'Load Files' },
        path: '/tms/load-files',
      },
      {
        code: 'note',
        label: { zh_CN: '笔记', en_US: 'Note' },
        path: '/tms/note',
      },
      {
        code: 'salary-list',
        label: { zh_CN: '薪资清单', en_US: 'Salary List' },
        path: '/tms/salary-list',
      },
      {
        code: 'load',
        label: { zh_CN: '加载', en_US: 'Loads' },
        path: '/tms/load',
      },
      {
        code: 'broker',
        label: { zh_CN: '经纪人', en_US: 'Brokers' },
        path: '/tms/broker',
      },

      {
        code: 'city',
        label: { zh_CN: '城市', en_US: 'Cities' },
        path: '/tms/city',
      },
      {
        code: 'location',
        label: { zh_CN: '地点', en_US: 'Locations' },
        path: '/tms/location',
      },
      {
        code: 'tag',
        label: { zh_CN: '标签', en_US: 'Tags' },
        path: '/tms/tag',
      },
      {
        code: 'zipCode',
        label: { zh_CN: '邮政编码', en_US: 'ZIP Codes' },
        path: '/tms/zip-code',
      },
      {
        code: 'category',
        label: { zh_CN: '类别', en_US: 'Categories' },
        path: '/tms/category',
      },
      {
        code: 'contact',
        label: { zh_CN: '接触', en_US: 'Contacts' },
        path: '/tms/contact',
      },
      {
        code: 'device',
        label: { zh_CN: '设备', en_US: 'Devices' },
        path: '/tms/device',
      },
      {
        code: 'report',
        label: { zh_CN: '报告', en_US: 'Reports' },
        path: '/tms/report',
      },
      {
        code: 'unit',
        label: { zh_CN: '报告', en_US: 'Units' },
        path: '/tms/unit',
      },
    ],
  },
];
